// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR');
  });
}

// CSS
import '../css/index.css';

// Stimulus Controllers
import './controllers';

// Vendor
// Lazyloading
import 'lazysizes';
lazySizes.cfg.expand = 250; // Set viewport expand for lazyload (Default: 375-500)

// Block CSS Transitions
// On Load
document.addEventListener('DOMContentLoaded', () => {
  const node = document.querySelector('.no-transitions');
  node.classList.remove('no-transitions');
});
// On Resize
let resizeTimer;
window.addEventListener('resize', () => {
  document.body.classList.add('no-transitions');
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(() => {
    document.body.classList.remove('no-transitions');
  }, 400);
});
